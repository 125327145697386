.failed {
  padding: 2rem 2rem;
  padding-bottom: 4rem;
  padding-top: 3rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
    // padding-top: 0;
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
  }

  h3 {
    color: #db4141;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
  p {
    color: #666876;
    font-size: 0.9rem;
    width: 80%;
    text-align: center;
    margin: auto;
    margin-top: 0.5rem;
    @media (max-width: 500px) {
      font-size: 0.8rem;
      width: 90%;
    }
  }

  button {
    width: 100%;
    height: 50px;
    background: #556bf6;
    border-radius: 8px;
    margin-top: 1.5rem;
    font-family: inherit;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
  }

  // &__buttons {
  //   width: 100%;
  //   max-width: 400px;
  //   margin: auto;
  //   display: grid;
  //   grid-template-columns: 1fr 100px;
  //   grid-gap: 1rem;
  //   margin-top: 1rem;

  //   button {
  //     width: 100%;
  //     height: 55px;
  //     max-width: 300px;
  //     background: #0f295c;
  //     border-radius: 6px;
  //     font-family: inherit;
  //     font-size: 1rem;
  //     color: #fff;
  //     display: block;
  //     margin: 0 auto;
  //     margin-top: 1.5rem;
  //     cursor: pointer;

  //     &:nth-child(2) {
  //       background: #e2e2e2;
  //       color: #5d5d5d;
  //       display: grid;
  //       place-content: center;
  //     }

  //     img {
  //       width: 30px;
  //     }
  //   }
  // }

  &__telegram {
    width: 35px !important;
  }
}
