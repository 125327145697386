.confirmTransfer {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  padding-top: 0;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 4rem;
    padding-top: 0;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    text-align: center;
    margin-top: -1.1rem;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  &__form {
    margin-top: 3rem;

    &__error {
      color: tomato;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }

    &__notice {
      display: grid;
      grid-template-columns: 20px 1fr;
      color: rgba(255, 165, 0, 1);
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
    &__account {
      display: grid;
      place-content: center;
      background: #f4f4f4;
      border: 1px solid #d1d1d1;
      min-height: 120px;
      border-radius: 6px;
      margin-top: 0.3rem;
      padding: 1rem;
      position: relative;
      &__copy {
        // position: absolute;
        // top: 1.2rem;
        // right: 1rem;
        cursor: pointer;
        padding-left: 0.1rem;
      }
      &__details {
        p {
          color: #a2a2a2;
          font-size: 0.9rem;
          text-align: center;
          margin: auto;
          margin-top: 0.5rem;
          display: flex;
          align-items: center;
          width: fit-content;
        }
      }
    }
    &__item {
      margin-top: 1.2rem;
      label {
        color: #a2a2a2;
        font-size: 0.9rem;
      }

      button {
        background: #0f295c;
        height: 55px;
        border-radius: 6px;
        width: 100%;
        color: #fff;
        font-family: inherit;
        font-size: 1rem;
        font-weight: 500;
        margin-top: 2rem;
        cursor: pointer;
        display: grid;
        place-content: center;
        p {
          display: flex;
          align-items: center;
          width: fit-content;
          color: #fff;
        }
        span {
          display: flex;
          align-items: center;
          width: fit-content;
          margin-right: 0.4rem;
        }
      }

      &__input {
        width: 100%;
        height: 55px;
        display: block;
        background: #f4f4f4;
        border: 1px solid #d1d1d1;
        border-radius: 6px;
        margin-top: 0.3rem;
        outline: none;
        padding: 0 1rem;
        font-family: inherit;
        font-size: 1rem;
      }

      &__card {
        margin-top: 0.3rem;
        background: #f4f4f4 !important;
        border: 1px solid #d1d1d1 !important;
        height: 55px !important;
        // width: 100% !important;
        border-radius: 6px !important;
        display: grid !important;
        grid-template-columns: 30px 1fr 80px 60px !important;

        input {
          background: #f4f4f4 !important;
        }
        &__container {
          width: 100% !important;
        }
      }
    }
  }
}
