.navbar {
  //background: #3c8f57;
  background:  #1b1b1b !important;;
  height: 100px;
  padding: 0 6rem;
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 0 2rem;
  }
  @media (max-width: 600px) {
    padding: 0 1.5rem;
    grid-template-columns: 70px 1fr;
    height: 80px;
  }
  @media (max-width: 355px) {
    padding: 0 1rem;
  }

  &__logo {
    width: 120px;
    @media (max-width: 600px) {
      width: 100px;
    }
  }
  &__text {
    color: #fff;
    font-size: 1.4rem;
    font-weight: 600;
    @media (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
}
