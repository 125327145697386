.inputPin{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: .2rem;

    input{
       width: 100%;
       max-width: 60px;
       height: 60px;
       background:pink;
       border-radius: 12px;
       padding: .5rem;
       font-size: 2rem;
       display: block;
       margin: auto;
       text-align: center;
       background: #F3F3F3;
       outline: 0;
       border: none;
       color: #000;
       font-weight: 600;
    }
}