.invalid {
  padding: 2rem 2rem;
  padding-bottom: 5rem;
  padding-top: 3rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 4rem;
    // padding-top: 0;
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
  }
  h3 {
    color: #db4141;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
  p {
    color: #666876;
    font-size: 0.9rem;
    width: 90%;
    text-align: center;
    margin: auto;
    margin-top: 0.5rem;
    @media (max-width: 500px) {
      font-size: 0.8rem;
      width: 90%;
    }
  }
}
