.success {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  padding-top: 3rem;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 2rem;
  }

  &__icon {
    width: 40%;
    display: block;
    margin: auto;
  }

  h3 {
    color: #1E9E40;
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center;
    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
  p {
    color: #666876;
    font-size: 0.9rem;
    width: 80%;
    text-align: center;
    margin: auto;
    margin-top: 0.5rem;
    @media (max-width: 500px) {
      font-size: 0.8rem;
      width: 90%;
    }
  }

  button {
    width: 100%;
    height: 50px;
    background: #556bf6;
    border-radius: 8px;
    margin-top: 1.5rem;
    font-family: inherit;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
  }
  
}
