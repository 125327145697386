.home {
  //background: #3c8f57 !important;
  background: #1b1b1b !important;
  color: #fff;
  min-height: 100vh;
  &__main {
    width: 100%;
    height: calc(100vh - 600px);
    min-height: 600px;
    // display: grid;
    // align-items: center;
    padding: 0 6rem;
    margin-top: 15vh;
    @media (max-width: 1024px) {
      padding: 0 2rem;
    }
    @media (max-width: 600px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 355px) {
      padding: 0 1rem;
    }

    h1 {
      font-size: 3rem;
      color: #fff;
      font-weight: 800;
      margin-bottom: 2rem;
      text-align: center;

      @media (max-width: 680px) {
        //  background: red;
        max-width: 350px;
        margin: auto;
        margin-bottom: 2rem;
        line-height: 1.1;
      }
      @media (max-width: 500px) {
        font-size: 2rem;
        max-width: 250px;
        line-height: 1.2;
      }
    }

    &__inputBox {
      width: 100%;
      max-width: 600px;
      height: 70px;
      border-radius: 10px;
      background: #fff;
      margin: auto;
      background: #31313d;
      border: 2px solid #606071;
      border-radius: 14px;
      display: grid;
      grid-template-columns: 122px 1fr 100px;
      padding: 0.3rem;
      align-items: center;
      box-sizing: border-box;
      @media (max-width: 480px) {
        grid-template-columns: 122px 1fr 80px;
      }

      p {
        color: #54545a;
        font-size: 0.8rem;
        padding-left: 1rem;

        box-sizing: border-box;
        b {
          font-size: 1.1rem;
          color: #b7b7b7;
          font-weight: 400;
          padding-left: 0.1rem;
        }
      }

      input {
        color: #b7b7b7;
        height: 100%;
        width: 100%;
        padding-right: 1rem;
        color: #b7b7b7;
        font-family: inherit;
        font-size: 1.1rem;
        box-sizing: border-box !important;
        outline: none;
        border: none;
        background: transparent;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
      button {
        width: 100%;
        height: 100%;
        background: #0f295c;
        border-radius: 10px;
        color: #fff;
        font-weight: 500;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
  }
}
