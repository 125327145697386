.crypto {
  padding: 2rem 4rem;
  padding-bottom: 4rem;
  padding-top: 0;
  @media (max-width: 500px) {
    padding: 2rem 1rem;
    padding-bottom: 4rem;
    padding-top: 0;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #444;
    text-align: center;
    margin-top: -1.1rem;
    margin-bottom: 0.2rem;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }

  &__loading {
    height: 400px;
    width: 100%;
    display: grid;
    place-content: center;

    &__content {
      display: flex;
      align-items: center;
      p {
        margin-right: 0.4rem;
        font-weight: 500;
      }
    }
  }

  &__subHeading {
    font-size: 1rem;
    font-weight: 500;
    color: #444444c5;
    text-align: center;
    margin-top: 0.3rem;
    @media (max-width: 600px) {
      font-size: 0.9rem;
      margin-top: 0.2rem;
    }
  }

  &__main {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 1rem;

    &__right {
      // background: #EFEFEF;
      padding-top: 2rem;
    }

    &__left {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // flex-direction: column;
      padding-top: 4rem;
    }
    &__select {
      width: 100%;
      margin-bottom: 4rem;
    }

    &__warning {
      display: grid;
      grid-template-columns: 22px 1fr;
      margin-top: 3rem;
      p {
        color: #696969;
        font-size: 0.9rem;
        font-weight: 400;
      }
    }

    &__button {
      border-radius: 8px;
      background: #0f295c;
      font-family: inherit;
      font-weight: 500;
      width: 100%;
      height: 55px;
      font-size: 0.9rem;
      color: #fff;
      display: grid;
      place-content: center;
      cursor: pointer;
    }

    &__qrCode {
      // background: red;
      margin: auto;
      width: fit-content;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;
    }

    &__link {
      max-width: 220px;
      margin: auto;
      margin-bottom: 0rem;

      height: 30px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 25px;
      align-items: center;
      background: #f5f5f5;
      border-radius: 5px;

      span {
        font-size: 0.9rem;
        display: inline-block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        background: #f5f5f5;
        padding: 0 0.4rem;
        border-radius: 5px;
        height: 30px;
        display: grid;
        align-items: center;
      }
    }

    &__amount {
      margin-top: 0rem;
      margin-bottom: 1rem;

      &__rate {
        font-size: 1rem;
        font-weight: 500;
        color: #696969;
        text-align: center;
        margin: 0.5rem 0;
        margin-top: 0.5rem;
        margin-bottom: 0.1rem;
      }
      &__naira {
        text-align: center;
        display: flex;
        align-items: center;
        font-weight: 800;
        color: #5c5c5c;
        // background: red;
        width: fit-content;
        height: fit-content;
        margin: 0;
        margin: 0 auto;
        font-size: 2.5rem;
      }
      // img {
      //   width: 100%;
      //   display: block;
      //   max-width: 250px;
      //   margin: 0 auto;
      // }

      &__crypto {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        color: #696969;
        margin: 0 auto;
        display: flex;
        align-items: center;
        width: fit-content;
        margin-bottom: 0.1rem;
        margin-top: -0.3rem;

        &__img {
          width: 20px;
          margin: 0 auto;
          display: block;
        }
        p {
          display: block;
        }
        img {
          width: 20px;
          margin-left: 0.1rem;
          display: block;
        }
      }
    }
  }
}
