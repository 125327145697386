.cardDetails {
  padding: 1rem 2rem;
  padding-bottom: 3rem;
  padding-top: 0;
  @media (max-width: 500px) {
    padding: 1rem 1rem;
    padding-bottom: 3rem;
    padding-top: 0;
  }

  &__heading {
    font-size: 1.3rem;
    font-weight: 600;
    color: #222222;
    text-align: left;
    margin-top: 1rem;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }
  &__desc {
    font-size: 0.9rem;
    color: #666876;
  }

  &__form {
    margin-top: 1rem;

    &__error {
      color: tomato;
      font-size: 0.9rem;
      margin-top: 0.5rem;
    }
    &__item {
      margin-top: 1.2rem;
      label {
        color: #7b7c86;
        font-size: 0.9rem;
        font-weight: 500;
      }

      button {
        background: #556bf6;
        border-radius: 8px;
        height: 55px;
        width: 100%;
        color: #fff;
        font-family: inherit;

        font-size: 1rem;
        font-weight: 600;
        margin-top: 2rem;
        cursor: pointer;
        display: grid;
        place-content: center;
      }

      &__input {
        width: 100%;
        height: 55px;
        display: block;
        // border: 1px solid #d1d1d1;
        border: none;
        margin-top: 0.3rem;
        outline: none;
        padding: 0 1rem;
        font-family: inherit;
        font-size: 1rem;
        background: #eff0f3;
        border-radius: 8px;
      }

      &__card {
        margin-top: 0.3rem;
        background: #eff0f3 !important;
        border: 0px solid #d1d1d1 !important;
        height: 55px !important;
        // width: 100% !important;
        border-radius: 6px !important;
        display: grid !important;
        grid-template-columns: 30px 1fr 80px 60px !important;

        input {
          background: #f4f4f4 !important;
        }
        &__container {
          width: 100% !important;
        }
      }
    }
  }
}
