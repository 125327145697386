.paymentDetails {
  padding: 1rem 2rem;
  padding-bottom: 3rem;
  font-family: "Poppins";
  @media (max-width: 500px) {
    padding: 1rem 1rem;
    padding-bottom: 3rem;
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #222222;
    line-height: 1.3;
    margin-top: 2rem;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }
  &__desc {
    font-size: 0.9rem;
    margin-top: 0.2rem;
    color: #666876;
  }
  &__loading {
    height: 400px;
    width: 100%;
    display: grid;
    place-content: center;

    &__content {
      display: flex;
      align-items: center;
      p {
        margin-right: 0.4rem;
        font-weight: 500;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    padding-right: 0.4rem;
    h4 {
      margin: 0;
      padding: 0;
      margin-left: 0.1rem;
      font-weight: 500;
      font-size: 1.1rem;
    }
  }

  // &__desc {
  //   color: #696969;
  //   margin: auto;
  //   width: fit-content;
  //   font-size: 1.1rem;
  //   margin-top: 1rem;
  //   margin-bottom: 1rem;
  //   @media (max-width: 480px) {
  //     font-size: 1rem;
  //   }
  //   b {
  //     color: #444;
  //   }
  //   span {
  //     display: block;
  //     margin: auto;
  //     width: fit-content;
  //   }
  // }

  &__icon {
    width: 70px;
    height: 70px;
    background: #f0f0f0;
    display: grid;
    place-content: center;
    margin: auto;
    margin-top: 2rem;
    border-radius: 50px;

    img {
      width: 40px;
      display: block;
    }
  }
  &__info {
    margin-top: 3rem;

    &__tabs {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      border-bottom: 1px solid #d9d9d9;
    }

    &__tab {
      margin: 0 0.4rem;
      cursor: pointer;
      p {
        color: rgba(64, 64, 65, 0.5);
        font-weight: 500;
        font-size: 0.95rem;
      }

      &__active {
        padding-top: 4px;
        p {
          font-weight: 600;
          color: #0f295c;
          border-bottom: 5px solid #556bf6;
          border-radius: 0px;
          padding-bottom: 0.1rem;
        }
      }
    }

    &__status {
      margin-top: 2rem;
      &__item {
        display: grid;
        grid-template-columns: 30px 3fr;
        // align-items: center;
        height: 70px;
        @media (max-width: 600px) {
          grid-template-columns: 30px 1fr;
        }
        @media (max-width: 480px) {
          grid-template-columns: 30px 1fr;
        }
        h4 {
          text-align: right;
          margin-right: 1rem;
          font-size: 0.9rem;
          color: #222222;
        }
        p {
          font-size: 0.9rem;
          font-weight: 500;
          color: #222222;
        }
        h5 {
          color: #666876;
        }

        h5 {
          font-size: 0.8rem;
        }

        &__link {
          width: 100%;
          height: 100%;
          position: relative;

          &__head {
            position: absolute;
            width: 26px;
            height: 26px;
            background: #fdb62f;
            border-radius: 50%;
            border: 4px solid #fff1d6;
          }

          &__body {
            background: #fdb62f;
            width: 4px;
            height: calc(100% - 6px);
            position: absolute;
            left: 10.5px;
            bottom: 100%;
          }
        }

        &:first-child {
          .paymentDetails__info__status__item__link__body {
            display: none;
          }
        }

        &__disabled {
          h5 {
            color: rgba(128, 128, 128, 0.625);
          }
          p {
            color: rgba(128, 128, 128, 0.625);
          }
          .paymentDetails__info__status__item__link {
            &__head {
              background: rgba(128, 128, 128, 0.61);
              border: 4px solid #f3f3f2;
            }
            &__body {
              background: rgba(128, 128, 128, 0.61);
              opacity: 0.5;
              height: calc(100% - 22px);
            }
          }
        }
      }
    }

    &__details {
    }

    &__detail {
      margin-top: 1rem;
      &__heading {
        color: #7d7d81;
        font-size: 0.8rem;
      }

      &__content {
        background: #e6eaff;
        border-radius: 11px;
        border: 1px dashed rgba(85, 107, 246, 0.6);
        margin-top: 0.5rem;
        padding: 0 1rem;
        padding-bottom: 1rem;
        padding-top: 0.1rem;
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
          h5 {
            color: #888b99;
            font-weight: 500;
            font-size: 0.85rem;
            margin: 0;
            padding: 0;
            //white-space: nowrap
          }
          p {
            color: #222222;
            font-weight: 500;
            font-size: 0.85rem;
            margin: 0;
            padding: 0;
            -ms-word-break: break-all;
            word-break: break-all;

            /* Non standard for webkit */
            word-break: break-word;

            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
          }
        }
      }
    }
  }
}
