.options {
  padding: 1rem 2rem;
  padding-bottom: 3rem;
  @media (max-width: 500px) {
    padding: 1rem 1rem;
    padding-bottom: 3rem;
  }

  &__loading {
    height: 400px;
    width: 100%;
    display: grid;
    place-content: center;

    &__content {
      display: flex;
      align-items: center;
      p {
        margin-right: 0.4rem;
        font-weight: 500;
      }
    }
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #444;
    line-height: 1.5;
    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
    color: #222222;
    line-height: 1.3;
    margin-top: 2rem;
    @media (max-width: 600px) {
      font-size: 1.3rem;
    }
  }
  &__desc {
    font-size: 0.9rem;
    margin-top: 0.2rem;
    color: #666876;
  }

  &__items {
    margin-top: 1rem;
  }

  &__item {
    border-radius: 8px;
    min-height: 70px;
    width: 100%;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 30px 1fr 20px;
    padding: 1rem 1rem;
    cursor: pointer;
    margin-top: 1.5rem;
    grid-gap: 1rem;

    background: #ffffff;
    border: 1px solid #e7e7e9;
    border-radius: 10px;

    &__disabled {
      // &__blurred {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        //background: red;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(2.9px);
        -webkit-backdrop-filter: blur(12.9px);
        border-radius: 8px;
        top: 0;
        left: 0;
      }
      // }
    }
    p {
      color: #a2a2a2;
      font-size: 0.9rem;
    }

    &__ring {
      width: 20px;
      height: 20px;
      border: 1px solid #cfd1d6;
      border-radius: 50%;

      &__active {
        background: #fff;
        border: 1px solid #556bf6;
        position: relative;

        &::after {
          position: absolute;
          background: #556bf6;
          content: "";
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: scale(0.7);
          border-radius: 50%;
        }
      }
    }

    &__img {
      width: 35px;
      height: 35px;
      background: rgba(85, 107, 246, 0.1);
      border-radius: 5px;
      display: grid;
      place-content: center;
      img {
        width: 25px;
      }
    }

    &__info {
      h3 {
        font-size: 1rem;
        color: #222222;
        font-weight: 600;
        margin-top: -0.3rem;
      }
      p {
        color: #7b7c86;
        font-weight: 400;
        font-size: 0.8rem;
      }
    }
  }

  &__submit {
    width: 100%;
    height: 50px;
    background: #556bf6;
    border-radius: 8px;
    margin-top: 1.5rem;
    font-family: inherit;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;

    &__disabled {
      background: #c7c9d4;
    }
  }
}
